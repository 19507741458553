const lang = {
    login: {
        welcomeLogin: '欢迎登录',
        cnLang: '简体中文',
        enLang: '英文',
        contactUs: '联系我们',
        accountLogin: '账号登录',
        smsLogin: '短信登录',
        accountPlaceholder: '请输入账号',
        pwdPlaceholder: '请输入密码',
        phonePlaceholder: '请输入手机号',
        correctPhoneTip: '请输入正确的手机号',
        captchaPlaceholder: '请输入验证码',
        rememberPwd: '记住密码',
        sliderCheckTip: '拖动滑块验证并登录',
        sliderCheckSuccess: '验证成功',
        changePwd: '修改密码',
        exitSystem: '退出系统',
        exitTip: '确定要退出系统吗？',
        emptyMenu: '请配置角色菜单后重新刷新',
        accountValidTip: '请输入账号',
        pwdValidTip: '请输入密码',
        phoneValidTip: '请输入手机号',
        captchaValidTip: '请输入验证码',
        login: '登录',
        smsCaptcha: '验证码',
        getCaptcha: '获取验证码',
        loginSuccess: '登录成功',
        safetyMode: '安全验证'
    },

    changePwd: {
        oldPwd: '原始密码',
        newPwd: '新密码',
        confirmPwd: '确认密码',
        oldPwdValidTip: '请输入原始密码',
        newPwdValidTip: '请输入新密码',
        confirmPwdValidTip: '请输入确认密码',
        pwdValidTip: '两次输入的密码不一致',
        loginAgainTip: '更新成功，请退出重新登录'
    },

    tags: {
        reload: '重新加载',
        closeCurrTab: '关闭当前标签页',
        closeOtherTabs: '关闭其他标签页',
        closeAllTabs: '关闭全部标签页'
    },

    operation: {
        refresh: '刷新',
        export: '导出',
        batchImport: '批量导入',
        batchDelete: '批量删除',
        add: '增加',
        edit: '编辑',
        del: '删除',
        detail: '详情',
        search: '搜索',
        reset: '重置',
        save: '保存',
        stockOut: '出库',
        batchStockOut: '批量出库',
        submit: '提交',
        cancel: '取消',
        cmd: '指令',
        vehicleRentEnable: '租车启用',
        vehicleRentDisable: '租车停用',
        vehicleLock: '车辆上锁',
        vehicleUnlock: '车辆开锁',
        searchVehicle: '寻车鸣笛',
        vehicleStalled: '车辆熄火',
        vehicleStartup: '车辆启动',
        rebootTbox: '重启中控',
        queryLocation: '位置查询',
    },

    common: {
        tips: '提示',
        order: '序号',
        operation: '操作',
        createTime: '创建时间',
        createBy: '创建人',
        updateTime: '更新时间',
        updateBy: '更新人',
        remark: '备注',
        enableState: '启用状态',
        enableColumn: '启用状态',
        organizationId: '运营商ID',
        organization: '所属运营商',
        rentalSite: '所属站点',
        rentalStore: '所属门店',
        batterySite: '所属租电站点',
        vehicleStore: '所属租车门店',
        warehouse: '所属仓库',
        setmeal: '所属套餐',
        selectTip: '请选择',
        addTip: '点击右边按钮增加',
        selectDate: '选择日期',
        inputIconNameTip: '请输入图标名称',
        orgValidTip: '请选择所属运营商',
        rentalSiteValidTip: '请选择所属站点',
        enableValidTip: '请选择启用状态',
        regionValidTip: '请选择所属地区',
        selectOrgFirstTip: '请先选择运营商',
        noPermissionTip: '无查询权限',
        exportSuccess: '导出成功',
        exportFail: '导出失败',
        importSuccess: '导入成功',
        addSuccess: '添加成功',
        updateSuccess: '更新成功',
        deleteSuccess: '删除成功',
        syncSuccess: '同步成功',
        saveSuccess: '保存成功',
        cancelSuccess: '取消成功',
        operateSuccess: '操作成功',
        deleteTip: '确认执行删除操作？',
        cancelConfirm: '确认取消？',
        processConfirm: '确认处理？',
        sendCmdTip: '确定发送指令?',
        confirmTip: '确定',
        cancelTip: '取消',
        yes: '是',
        no: '否',
        enable: '启用',
        disable: '禁用',
        associatePhotos: '关联照片',
        more: '更多',
        fold: '收起',
        deviceNum: '设备编号',
        deviceNumValidTip: '请输入设备编号',
        checkboxTip: '请勾选数据',
        checkOrgEqualTip: '请勾选相同运营商的数据',
        selectedNum: '已选择数量',
        inventoryStatus: '库存状态',
        lng: '经度',
        lat: '纬度',
        region: '所属地区',
        address: '地址',
        addressValidTip: '请输入地址',
        addressEmptyTip: '地址不能为空',
        managerNameValidTip: '请输入管理人姓名',
        managerPhoneValidTip: '请输入管理人手机',
        loginTime: '登录时间',
        logoutTime: '登出时间',
        onlineDuration: '在线时长',
        loginIp: 'IP',
        loginType: '登录类型',
        imsi: 'IMSI',
        hardVsn: '硬件版本',
        softVsn: '软件版本',
        protocolVsn: '协议版本',
        alarmCategory: '告警类别',
        alarmType: '告警名称',
        operationTime: '操作时间',
        operator: '操作用户',
        operatorPhone: '用户手机',
        orderNumValidTip: '请输入排序值',
        cnMoneyUnit: '元',
        integralUnit: '分',
        dayUnit: '天',
        secondUnit: '秒',
        hourUnit: '小时',
        minuteUnit: '分钟',
        speedUnit: '公里/时',
        mileageUnit: '公里',
        rice: '米',
        lenUnit: '位',
        validFrom: '有效开始时间',
        validTo: '有效结束时间',
        to: '至',
        rentalType: '租赁类型',
        selectFile: '选择文件',
        selectFileTip: '请选择文件',
        process: '处理',
        complete: '完结',
        file: '文件',
        reason: '原因',
        qrcode: '二维码',
        download: '下载',
        deleted: '已删除',
        unregister: '未注册',
        operationType: '操作类型',
        operationLog: '操作日志',
        batteryList: '电池列表',
        cabinetList: '电柜列表',
        vehicleList: '车辆列表',
        RentalUserList: '租赁客户列表',
        batterySwapRecordList: '换电记录',
        batteryOrderList: '换电订单',
        fundFlowList: '资金流水',
        partnerTransaction: '合作商交易记录',
        sendSuccess: '发送成功',
        today: '今天',
        yesterday: '昨天',
        aThreeDayAgo: '最近三天',
        aWeekAgo: '最近一周',
        aMonthAgo: '最近一个月',
        aThreeMonthAgo: '最近三个月',
        aHalfYearAgo: '最近半年',
        aYearAgo: '最近一年',
        statisticsStartTime: '统计开始时间',
        statisticsEndTime: '统计结束时间',
        createStartTime: '创建开始时间',
        createEndTime: '创建结束时间',
        success: '成功',
        fail: '失败',
        startTime: '开始时间',
        siteGroup: '站点分组',
        auditStatus: '审核状态',
        auditTime: '审核时间',
        auditUserName: '审核人',
        detail: '明细',
        failedReason: '失败原因',
        audit: '审核',
        partner: '合作商',
        personal: '个人',
        uploadPhoto: '上传照片',
        price: '价格',
        sharingUser: '分账用户',
        disabled: '禁用',
        updateFail: '更新失败',
        deleteFail: '删除失败',
        inputValidAmountTip: '请输入有效金额',
        noDataExport: '没有数据可以导出',
        exportDataLimitTip: '导出数据量过大，请选择导出条件',
        couponProductOrderList: '券包订单列表',
        couponRecordList: '优惠券派发记录列表',
        goodsName: '商品名称',
    },

    map: {
        selectPoint: '地图取点',
        poiSearch: 'POI搜索',
        poiPlaceholder: '请输入关键字',
        selectPointConfirm: '是否将该点设置为地址？',
        lngValidTip: '请输入经度',
        latValidTip: '请输入纬度'
    },

    organization: {
        addTitle: '添加运营商',
        editTitle: '修改运营商',
        detailTitle: '运营商信息',
        name: '运营商名称',
        orgType: '组织类型',
        parent: '所属运营商',
        contacts: '联系人',
        phone: '联系电话',
        companyName: '公司全称',
        legalPerson: '法人',
        businessLicense: '经营许可证',
        businessScope: '经营范围',
        nameValidTip: '请输入运营商名称',
        orgTypeValidTip: '请选择组织类型',
        parentValidTip: '请选择所属运营商'
    },

    role: {
        addTitle: '添加角色',
        editTitle: '修改角色',
        detailTitle: '角色信息',
        name: '角色名称',
        permission: '角色权限',
        nameValidTip: '请输入角色名称'
    },

    user: {
        addTitle: '添加用户',
        editTitle: '修改用户',
        detailTitle: '用户信息',
        keyword: '姓名/账号/手机/邮箱',
        selectRoleTip: '请选择角色',
        username: '用户姓名',
        account: '登录账号',
        password: '密码',
        roles: '角色',
        phone: '手机号码',
        email: '邮箱',
        expireDate: '到期时间',
        orgPermissions: '组织权限',
        sitePermissions: '站点权限',
        partnerPermissions: '合作商权限',
        pwdTips: '6-16位数字、字母（区分大小写）组合',
        orgPermissionTips: '默认为用户所属运营商及下级的运营商',
        sitePermissionTips: '默认为用户所属运营商及下级的站点',
        partnerPermissionTips: '默认为用户所属运营商及下级的合作商',
        usernameValidTip: '请输入用户姓名',
        accountValidTip: '请输入登录账号',
        emailValidTip: '邮箱格式不正确',
        pwdValidTip: '请输入密码',
        incorrectPwdTip: '密码格式有误',
        phoneNoValidTip: '请输入手机号码',
        viewedSensitive: '查看敏感信息',
        allowEmptySites: '查看空站点数据',
        allow: '允许',
        deny: '不允许'
    },

    menu: {
        addTitle: '添加权限菜单',
        editTitle: '修改权限菜单',
        keyword: '菜单名称/编码',
        parent: '上级菜单',
        menuType: '菜单类型',
        name: '菜单名称',
        code: '编码',
        url: 'URL地址',
        icon: '图标',
        orderNum: '排序值',
        openNewPage: '打开新页面',
        selectIconTip: '点击选择图标',
        nameValidTip: '请输入菜单名称',
        codeValidTip: '请输入编码',
        menuTypeValidTip: '请选择菜单类型'
    },

    dict: {
        addTitle: '添加数据字典',
        editTitle: '修改数据字典',
        selectCategoryTip: '请选择数据类型',
        keyword: '类型名称/编码',
        name: '中文名称',
        nameEn: '英文名称',
        code: '编码',
        orderNum: '排序号',
        parent: '所属类型',
        nameValidTip: '请输入中文名称',
        codeValidTip: '请输入编码'
    },

    operationLog: {
        logDesc: '日志类型',
        requestIp: 'IP',
        browserName: '浏览器',
        osName: '操作系统',
        costTime: '请求耗时',
        location: '地点',
        requestMethod: '请求方法',
        requestParams: '请求参数',
        content: '操作内容'
    },

    userLoginLog: {
        loginResult: '登录结果',
        browserName: '浏览器',
        osName: '操作系统',
        loginLocation: '登录地点'
    },

    unregisterTerminal: {
        cabinetTitle: '未注册电柜',
        batteryTitle: '未注册电池',
        terminalNum: '设备编号',
        receiveTime: '接收时间',
        nodeName: '网关节点',
        protocolType: '协议类型'
    },

    batteryModel: {
        addTitle: '添加电池型号',
        editTitle: '修改电池型号',
        detailTitle: '电池型号信息',
        brand: '品牌',
        modelCode: '型号',
        batteryType: '电池类型',
        ratedVoltage: '额定电压',
        ratedCapacity: '额定容量',
        packSize: '电池尺寸',
        packWeight: '电池重量',
        batteryCnt: '单体电芯数量',
        bmsBrand: 'BMS品牌',
        bmsModel: 'BMS型号',
        bmsVsn: 'BMS软件版本',
        brandValidTip: '请输入品牌',
        batteryTypeValidTip: '请选择电池类型',
        ratedVoltageValidTip: '请输入额定电压',
        ratedCapacityValidTip: '请输入额定容量',
        modelCodeTip: '电压和容量组合',
        packSizeTip: 'mm*mm*mm',
        keyword: '品牌/型号/BMS品牌',
        bundledSetmeal: '已绑套餐',
        fullyMileage: '满电里程'
    },

    cabinetModel: {
        addTitle: '添加电柜型号',
        editTitle: '修改电柜型号',
        detailTitle: '电柜型号信息',
        brand: '品牌',
        modelCode: '型号',
        cabinetType: '电柜类型',
        cabinetSize: '机柜尺寸',
        spaceCnt: '仓位数量',
        spaceSize: '仓位尺寸',
        screenSize: '屏幕尺寸',
        screenUnit: '寸',
        protectionLevel: '防水等级',
        brandValidTip: '请输入品牌',
        modelCodeValidTip: '请输入型号',
        spaceCntValidTip: '请输入仓位数量',
        cabinetTypeValidTip: '请选择电柜类型',
        cabinetSizeTip: 'mm*mm*mm',
        spaceSizeTip: 'mm*mm*mm',
        keyword: '品牌/型号'
    },

    vehicleModel: {
        addTitle: '添加车辆型号',
        editTitle: '修改车辆型号',
        detailTitle: '车辆型号信息',
        brand: '品牌',
        modelCode: '型号',
        vehicleSize: '车辆尺寸',
        maxSpeed: '最高车速',
        weight: '整车重量',
        wheelSize: '轮圈尺寸',
        brandValidTip: '请输入品牌',
        modelCodeValidTip: '请输入型号',
        keyword: '品牌/型号'
    },

    warehouse: {
        addTitle: '添加仓库',
        editTitle: '修改仓库',
        detailTitle: '仓库信息',
        warehouseName: '仓库名称',
        managerName: '管理人姓名',
        managerPhone: '管理人手机',
        warehouseNameValidTip: '请输入仓库名称',
        keyword: '仓库名称/管理人'
    },

    battery: {
        addTitle: '添加电池',
        editTitle: '修改电池',
        detailTitle: '电池信息',
        brand: '电池品牌',
        modelCode: '电池型号',
        batteryModelValidTip: '请选择电池型号',
        rentalSiteValidTip: '请选择所属站点',
        keyword: '电池名称/编号/BMS',
        batteryNameTip: '默认使用电池编号',
        deviceNumValidTip: '请输入电池编号',
        batteryName: '电池名称',
        batteryNum: '电池编号',
        bmsNum: 'BMS编号',
        rfidNum: 'RFID编号',
        selectTip: '请选择电池',
        siteGroup: '站点分组',
        batteryCycle: '循环次数',
        warehouseValidTip: '请选择所属仓库',
    },

    cabinet: {
        addTitle: '添加电柜',
        editTitle: '修改电柜',
        detailTitle: '电柜信息',
        businessDiyTitle: '电柜功能定制',
        cabinetName: '电柜名称',
        cabinetNum: '电柜编号',
        brand: '电柜品牌',
        modelCode: '电柜型号',
        cabinetNumValidTip: '请输入电柜编号',
        cabinetModelValidTip: '请选择电柜型号',
        rentalSiteValidTip: '请选择所属站点',
        cabinetNameTip: '默认使用电柜编号',
        keyword: '电柜名称/编号',
        doorId: '仓门号',
        selectTip: '请选择电柜',
        siteGroup: '站点分组',
        batteryCnt: '柜内电池数',
        enableTakeBattery: '取电功能',
        enableExchangeBattery: '换电功能',
        enableReturnBattery: '还电功能',
        showLocation: '展示位置',
        enableBatteryReturnCharge: '无可用电池归还充电',
        businessDiy: '功能定制',
        cascadeCabinetNo: '级联柜编号',
        cascadeCabinetList: '级联柜列表'
    },

    vehicle: {
        addTitle: '添加车辆',
        editTitle: '修改车辆',
        detailTitle: '车辆信息',
        vehicleName: '车辆名称',
        vin: '车架号',
        brand: '车辆品牌',
        modelCode: '车辆型号',
        tboxNum: 'Tbox编号',
        tboxProvider: 'Tbox厂商',
        vehicleNum: '车牌号',
        motorNum: '电机号',
        tboxFactory: 'Tbox厂家',
        insuranceNum: '保险号',
        vehicleColor: '车辆颜色',
        siteGroup: '站点分组',
        keyword: '名称/车架号/Tbox编号/车牌号',
        rentalSiteValidTip: '请选择所属站点',
        rentalStoreValidTip: '请选择所属门店',
        vehicleNameTip: '默认使用车架号',
        deviceNumValidTip: '请输入车架号',
        vehicleModelValidTip: '请选择车辆型号',
        selectTip: '请选择车辆',
        vehicleStore: '车辆所属门店',
        tboxFunctionList: 'Tbox功能',
        userTboxFunctionList: '用户Tbox功能',
        userTboxFunctionListTip: '允许用户使用中控功能',
        selectUserTboxFunctionListTip: '请先选择车辆型号',
    },
    finance: {
        category: '分类',
        amount: '租金金额（元）',
        deposit: '押金（元）',
        guarantee: '服务保障费（元）'
    },
    terminal: {
        onlineStatus: '在线状态',
        positionStatus: '定位状态',
        monitorStatus: '监控',
        track: '行驶轨迹',
        cmd: '远程控制',
        batteryCtrl: '控制电池',
        deviceName: '设备名称',
        deviceNum: '设备编号',
        terminalCategory: '设备类型',
        brand: '品牌',
        modelCode: '型号',
        keyword: '设备名称/设备编号',
        loginStartTime: '登录开始时间',
        loginEndTime: '登录结束时间',
        logoutStartTime: '登出开始时间',
        logoutEndTime: '登出结束时间',
        online: '在线',
        offline: '离线'
    },

    batteryStatus: {
        batteryStatus: '电池状态',
        ctrlStatus: '控制状态',
        batteryBusiness: '业务状态',
        soc: '电量SOC',
        chargeOpen: '充电开关',
        dischargeOpen: '放电开关',
        collectionTime: '数据采集时间'
    },

    cabinetStatus: {
        cabinetStatus: '电柜状态',
        collectionTime: '数据采集时间',
        protocolVsn: '协议版本',
        hardVsn: '硬件版本',
        softVsn: '软件版本',
        imei: 'IMEI',
        ccid: 'CCID',
        imsi: 'IMSI',
        disabledDoors: '禁用仓门',
        unchargeDoors: '不充电仓门',
        vol48: '48V可用',
        vol60: '60V可用',
        vol72: '72V可用',
        cabinetFaults: '电柜故障',
        fault04: '市电故障',
        fault08: '空开故障'
    },

    vehicleStatus: {
        vehicleStatus: '车辆状态',
        vehicleBusiness: '业务状态',
        batDt: '电池更新时间',
        gpsTime: '位置更新时间',
        updateTime: '数据更新时间',
        electStatus: '外接电源',
        electStatusDesc: '外接电源在位描述',
        batteryEU: '电压',
        lock: '车锁状态',
        lat: '原始纬度',
        lon: '原始经度',
        latC: '校准后纬度',
        lonC: '校准后经度',
        address: '车辆位置',
        positionStatus: '定位状态',
        positionStatusDesc: '定位状态描述',
        online: '在线状态',
        onlineDesc: '在线状态描述',
        status: '复合状态字',
        socPercent: 'GPS电量',
        gsm: 'GSM信号',
        soc: '电池电量',
        start: '车辆启动',
        startDesc: '车辆启动描述',
        speed: '速度',
        surplusMileage: '续航里程',
        drivingDistance: '行驶里程',
        serviceFlag: '租车启用状态',
        serviceFlagDesc: '租车启用状态描述',
        acc: 'ACC状态',
    },

    siteGroup: {
        addTitle: '添加站点分组',
        editTitle: '修改站点分组',
        detailTitle: '站点分组信息',
        groupName: '分组名称',
        groupNameValidTip: '请输入分组名称',
        keyword: '分组名称'
    },

    rentalSite: {
        addTitle: '添加站点',
        editTitle: '修改站点',
        detailTitle: '站点信息',
        siteGroup: '所属站点组',
        siteName: '站点名称',
        stationType: '站点类型',
        businessTime: '营业时间',
        cameras: '视频通道',
        storeFiles: '门店照片',
        licenseFiles: '营业执照',
        idCardFiles: '身份证',
        siteNameValidTip: '请输入站点名称',
        stationTypeValidTip: '请选择站点类型',
        keyword: '站点名称/管理人',
        managerName: '管理人姓名',
        managerPhone: '管理人手机',
        swapCabinetCnt: '换电柜数量',
        chargeCabinetCnt: '充电柜数量',
        batteryCnt: '电池数量',
        vehicleCnt: '车辆数量',
        siteAddress: '站点地址',
    },

    cameraChannel: {
        editTitle: '编辑视频通道',
        channelName: '通道名称',
        channelNameValidTip: '请输入通道名称'
    },

    alarm: {
        startTime: '告警开始时间',
        endTime: '告警结束时间',
        duration: '告警时长'
    },

    stock: {
        stockInTime: '入库时间',
        stockOutTime: '出库时间'
    },

    cabinetLoginLog: {
        iccid: 'ICCID',
        imei: 'IMEI'
    },

    cmdLog: {
        cmdType: '指令类型',
        responseTime: '响应时间',
        sendResult: '发送结果',
        userType: '用户类型',
        paramSendDesc: '发送参数'
    },

    setmeal: {
        addTitle: '添加套餐',
        editTitle: '修改套餐',
        detailTitle: '套餐信息',
        setmealListed: '套餐上架',
        setmealRemoval: '套餐下架',
        setmealNameValidTip: '请输入套餐名称',
        billingTypeValidTip: '请选择计费类型',
        rentAmountValidTip: '请输入租金',
        discountedRentValidTip: '请输入折扣后租金',
        depositAmountValidTip: '请输入押金',
        lossProtFeeValidTip: '请输入丢失保障费',
        discountFactorValidTip: '请输入折扣系数',
        billingCycleValidTip: '请输入计费周期',
        timesValidTip: '请输入次数',
        vehicleModelValidTip: '请选择车辆型号',
        singleExchangePriceValidTip: '请输入单次换电价格',
        keyword: '套餐名称',
        setmealStatus: '套餐状态',
        setmealName: '套餐名称',
        billingType: '计费类型',
        time: '时间',
        count: '次数',
        rentalBattery: '租电',
        rentAmount: '租金',
        discountedRent: '折扣后租金',
        depositAmount: '押金',
        lossProtFee: '丢失保障费',
        insuranceForce: '保障费购买不可选',
        insurancePolicy: '保障费计费类型',
        insuranceDays: '保障费有效天数',
        discountFactor: '折扣系数',
        billingCycle: '计费周期',
        times: '次数',
        singleExchangePrice: '单次换电价格',
        sitePermissionTips: '默认为运营商的所有站点',
        orderNum: '套餐排序值',
        purchaseCount: '购买数量',
        setmealRemark: '套餐说明',
        displayStrategy: '显示策略',
        setmealDisplayValidTip: '请选择显示策略',
        partner: '合作商',
        payLater: '先用后付',
        deductBySoc: '使用SOC计次',
        alipayCredit: '支付宝信用免押',
        repurchaseAllowed: '周期内可复购',
        setmealPermissionTips: '默认为运营商的所有套餐',
        partnerPayment: '合作商支付方式',
        gradeDiscountTables: '请勿勾选租金优惠过高等级'
    },

    chargeSetmeal: {
        unitPrice: '充电单价',
        unitPriceValidTip: '请输入单价',
        freeStorageDurationValidTip: '请输入免费寄存时长',
        storagePriceValidTip: '请输入寄存单价',
        name: '名称',
        billingDuration: '时长',
        totalPrice: '总价',
        orderNum: '排序值',
        addStrategy: '新增价格项',
        addSelfComplete: '新增充满自停',
        freeStorageDuration: '免费寄存时长',
        freeStorageTimeRange: '免费寄存时间段',
        storagePrice: '寄存单价',
        isRefundableEarlyFill: '按实际充电计费',
        batteryUserOnly: '仅限租电客户使用'
    },

    coupon: {
        addTitle: '添加优惠券',
        editTitle: '修改优惠券',
        detailTitle: '优惠券信息',
        couponName: '优惠券名称',
        couponStatus: '优惠券状态',
        discountType: '优惠方式',
        faceValue: '优惠券金额',
        discountDays: '优惠天数',
        useScope: '优惠券使用范围',
        inviteeSetmealScope: '受邀人套餐范围',
        issueType: '发放方式',
        isCumulative: '是否叠加使用',
        expirationDate: '有效期',
        couponNameValidTip: '请输入优惠券名称',
        discountTypeValidTip: '请选择优惠方式',
        faceValueValidTip: '请输入优惠券金额',
        discountDaysValidTip: '请输入优惠天数',
        useScopeValidTip: '请选择使用范围',
        issueTypeValidTip: '请选择发放方式',
        isCumulativeValidTip: '请选择是否叠加',
        expirationDateValidTip: '请选择有效期',
        setmealSelectTip: '请选择适用套餐',
        bindingSetmeal: '优惠券适用套餐',
        inviteeSetmeals: '受邀人指定套餐',
        setmealScopeValidTip: '请选择受邀人套餐范围',
        keyword: '优惠券名称',
        activeCoupon: '上架',
        activeTip: '确认生效吗?',
        distribute: '派发',
        distributeConfirmTip: '确认派发吗?',
        invalid: '作废',
        invalidTip: '此操作会将派发的卡券作废，确定操作吗?',
        distributeCoupon: '派发优惠券',
        distributeSuccess: '派发成功',
        offShelf: '下架',
        offShelfTip: '确认下架吗?',
        quantity: '数量',
        selectUserTip: '请选择用户',
        distributeCnt: '派发数量',
        usedCnt: '使用数量',
        validDays: '有效天数',
        purchaseAmount: '购买金额',
        purchaseAmountValidTip: '请输入购买金额',
        validDaysTip: '请输入有效天数',
    },

    frozenCoupon: {
        addTitle: '添加冻结券',
        editTitle: '修改冻结券',
        detailTitle: '冻结券信息',
        couponName: '冻结券名称',
        frozenDays: '冻结天数',
        couponNameValidTip: '请输入冻结券名称',
        frozenDaysValidTip: '请输入冻结天数',
        couponStatus: '冻结券状态',
        keyword: '冻结券名称',
        returnBattery: '归还电池',
        isBatterySoc: '判断电池电量',
    },

    couponRecord: {
        usageStatus: '使用状态',
        isAppDisplayed: 'APP已显示',
        usageTime: '使用时间',
        revokeTime: '撤销时间',
        revoke: '撤销',
        revokeConfirmTip: '确认撤销吗？'
    },

    rentalOrder: {
        detailTitle: '订单详情',
        orderNo: '订单号',
        transactionId: '交易单号',
        orderStatus: '订单状态',
        rentalUserPhone: '客户手机',
        rentalUserName: '客户姓名',
        rentalAmount: '租金',
        couponAmount: '优惠券',
        transactionAmount: '交易金额',
        expirationDate: '有效期',
        completionTime: '完结时间',
        payTime: '支付时间',
        orderKeyword: '订单号/交易单号',
        payStartTime: '支付开始时间',
        payEndTime: '支付结束时间',
        completionStartTime: '完结开始时间',
        completionEndTime: '完结结束时间',
        paymentMethod: '支付方式',
        refund: '退款',
        refundAmount: '退款金额',
        refundableAmount: '可退款金额',
        orderBusiness: '业务类型',
        voltageType: '电压类型',
        refundAmountEmptyTip: '退款金额不能为空',
        refundAmountGt0Tip: '退款金额必须大于0',
        refundAmountOverRangeTip: '金额不能大于可退款金额',
        refundAmountValieTip: '请输入正数或两位小数',
        dayCountValidTip: '请输入天数',
        timesValidTip: '请输入次数',
        voltageValidTip: '请选择电压',
        completeOrder: '完结订单',
        changeSetmeal: '更改套餐',
        changeVoltage: '更改电压',
        addDays: '增加天数',
        reduceDays: '减少天数',
        dayCount: '天数',
        addTimes: '增加次数',
        reduceTimes: '减少次数',
        times: '次数',
        completionUserName: '完结操作人',
        createUserName: '线下创建人',
        settle: '结算',
        settlementStatus: '结算状态',
        settlementOrder: '结算订单',
        settledOperator: '结算操作人',
        settledTime: '结算时间',
        settled: '已结算',
        unsettled: '未结算',
        settledPaymentMethod: '结算支付方式',
        settleAmount: '结算金额',
        settleAmountValidTip: '结算金额不能为空',
        isProfitSharing: '是否分账',
        profitSharing: '分账',
        freezeSetmeal: '冻结套餐',
        setmealDate: '套餐有效期',
        pointsDiscount: '积分优惠金额',
        increaseLifespan: '积分赠送天数',
        setmealValidTip: '请选择套餐',
        freezeDays: '冻结天数',
    },

    expiredOrder: {
        setmealEndTime: '到期时间',
        expiredDays: '逾期天数',
        expiredStartTime: '到期开始时间',
        expiredEndTime: '到期结束时间'
    },

    batterySwapRecord: {
        serviceType: '业务类型',
        swapStatus: '换电状态',
        oldBatteryNum: '原电池编号',
        newBatteryNum: '新电池编号',
        intoCabinetNum: '放电电柜编号',
        fromCabinetNum: '取电电柜编号',
        putBatteryTime: '放电时间',
        takeBatteryTime: '取电时间',
        rentalSite: '所属站点'
    },

    batteryChargeRecord: {
        chargeStatus: '充电状态',
        billingDuration: '充电时长',
        actualChargeDuration: '实际时长',
        chargeAmount: '充电费用',
        storageAmount: '寄存费用',
        storageDuration: '寄存时长',
        doorId: '仓门号',
        rentalSite: '充电站点',
        payTime: '支付时间',
        chargeStartTime: '充电开始时间',
        chargeEndTime: '充电结束时间',
        completeTime: '完结时间',
        bindChargeInfo: '绑定充电信息'
    },

    fundFlow: {
        fundType: '资金类型',
        settlementType: '结算方式'
    },

    balanceWithdrawal: {
        applicationTime: '申请时间',
        withdrawalAmount: '提现金额',
        audit: '审核',
        applicationStartTime: '申请开始时间',
        applicationEndTime: '申请结束时间',
        auditStartTime: '审核开始时间',
        auditEndTime: '审核结束时间',
        orderKeyword: '订单号',
        auditWithdrawal: '提现审核'
    },

    depositWithdrawal: {
        withdrawalAmount: '解冻金额',
        auditWithdrawal: '解冻审核',
        depositType: '押金类型',
        swapDeposit: '换电押金',
        vehicleDeposit: '车辆押金'
    },

    walletTransaction: {
        walletType: '钱包类型',
        transactionType: '交易类型',
        balance: '余额',
        refundAmount: '退款金额',
        amount: '金额',
        refundTime: '退款时间',
        rechargeOperator: '充值操作人'
    },

    batteryStorage: {
        storageAudit: '寄存审核',
    },

    rentalUser: {
        detailTitle: '租赁客户信息',
        editTitle: '修改租赁客户',
        rechargeTitle: '充值/退款',
        walletTransaction: '钱包交易记录',
        phoneNum: '客户手机',
        userName: '客户姓名',
        gender: '性别',
        idNum: '身份证号',
        authStatus: '认证状态',
        registerTime: '注册时间',
        balance: '总余额',
        walletBalance: '钱包余额',
        onlineBalance: '线上充值余额',
        offlineBalance: '线下充值余额',
        totalSwapDeposit: '换电总押金',
        swapDeposit: '换电押金',
        onlineSwapDeposit: '线上充值换电押金',
        offlineSwapDeposit: '线下充值换电押金',
        swapDepositDiscount: '换电押金优惠',
        totalVehicleDeposit: '车辆总押金',
        vehicleDeposit: '车辆押金',
        onlineVehicleDeposit: '线上充值车辆押金',
        offlineVehicleDeposit: '线下充值车辆押金',
        vehicleDepositDiscount: '车辆押金优惠',
        inviterType: '邀请人类型',
        inviterName: '邀请人姓名',
        inviterPhone: '邀请人手机',
        keyword: '客户手机/姓名/身份证',
        inviterKeyword: '邀请人手机/姓名',
        userStatus: '用户状态',
        registerStartTime: '注册开始日期',
        registerEndTime: '注册结束日期',
        ethnicity: '民族',
        birthDate: '出生日期',
        idValidDate: '身份证有效期',
        issueAuthority: '签发机关',
        address: '身份证住址',
        recharge: '充值',
        withdrawal: '退款',
        chargeback: '扣款',
        offlineRecharge: '线下充值',
        offlineWithdrawal: '线下退款',
        transactionAmountValidTip: '金额不能为空',
        phoneNumValidTip: '手机号不能为空',
        usernameValidTip: '客户姓名不能为空',
        idNumValidTip: '身份证号不能为空',
        walletType: '钱包类型',
        realnameAuth: '实名认证',
        purchaseBatterySetmeal: '购买电池套餐',
        authTime: '认证时间',
        male: '男',
        female: '女',
        idCard1: '身份证人像面',
        idCard2: '身份证国徽面',
        faceFile: '人脸照',
        baseInfo: '基础信息',
        walletInfo: '钱包信息',
        setmealInfo: '套餐信息',
        batterySetmeal: '换电套餐',
        vehicleSetmeal: '车辆套餐',
        setmealStatus: '套餐状态',
        startTimeValidTip: '请输入开始时间',
        endTimeValidTip: '请输入到期时间',
        disabledFace: '禁用人脸认证',
        promoter: '推广业务员',
        isPromoter: '是否推广业务员',
        vehiclePartner: '租车合作商',
        batteryPartner: '租电合作商',
        chargeVoltage: '充电电压',
        chargeVoltageValidTip: '充电电压不能为空',
        socLeft: '剩余换电SOC',
        depositStatus: '押金状态',
        thingsStatus: '领用状态',
        totalPoints: '总积分',
        batteryPoints: '租电积分',
        chargePoints: '充电积分',
        vehiclePoints: '租车积分',
        pointsGradeName: '积分等级',
        bindWxOpenId: '绑定微信号',
        unbindWxOpenId: '解绑微信号',
        bindAliOpenId: '绑定支付宝',
        unbindAliOpenId: '解绑支付宝',
    },

    inviteRecord: {
        registerTime: '注册时间',
        purchaseSetmealTime: '购买时间',
        setmealName: '套餐名称',
        purchaseStartTime: '购买开始时间',
        purchaseEndTime: '购买结束时间'
    },

    userSetmeal: {
        batterySetmealStartTime: '套餐开始时间',
        batterySetmealEndTime: '套餐结束时间',
        vehicleSetmealStartTime: '套餐开始时间',
        vehicleSetmealEndTime: '套餐结束时间',
        billingType: '计费方式',
        times: '次',
        changeBattery: '变更电池',
        unbindBattery: '解绑电池',
        changeVehicle: '变更车辆',
        unbindVehicle: '解绑车辆',
        newBattery: '新电池',
        newVehicle: '新车辆',
        setmealVoltage: '套餐电压',
        unbindBatteryConfirm: '确认解绑电池吗?',
        unbindVehicleConfirm: '确认解绑车辆吗?',
        completeOrderConfirm: '确认完结订单吗?',
        freezeSetmealConfirm: '确认冻结套餐吗?',
        hasBatteryConfirm: '用户有未归还的电池，确认完结订单吗?',
        hasVehicleConfirm: '用户有未归还的车辆，确认完结订单吗?',
        changeSetmealDateConfirm: '确认修改到期时间吗?',
        changeSetmealTimesConfirm: '确认修改次数吗?',
        changeVoltageConfirm: '确认修改套餐电压吗?',
        isDeductTimes: '扣减换电次数',
        swapTimesRemain: '换电剩余',
        setmealRemainingDays: '套餐剩余天数',
        setmealRemaining: '套餐剩余',
        freezeType: '冻结方式',
        freezeReason: '冻结原因',
        depositWithdrawalAuditing: '押金解冻审核中',
        batteryInsuranceStatus: '保障费有效期状态',
        batteryInsuranceStart: '保障费开始时间',
        batteryInsuranceEnd: '保障费结束时间',
        batteryInsuranceRemainingDays: '保障费剩余天数',
        batterySetmealTotalTimes: '剩余换电次数',
    },

    batteryTrack: {
        speed: '速度',
        mileage: '里程',
        soc: '电量SOC',
        vol: '电压'
    },

    faultWorkOrder: {
        addTitle: '添加故障工单',
        editTitle: '修改故障工单',
        detailTitle: '故障工单信息',
        orderNo: '工单编号',
        orderStatus: '工单状态',
        orderType: '工单类型',
        faultName: '故障名称',
        faultDesc: '故障描述',
        faultLevel: '故障等级',
        repairUser: '维修人',
        repairResult: '维修结果',
        processDesc: '处理描述',
        terminalValidTip: '请选择设备',
        faultNameValidTip: '请输入故障名称',
        faultLevelValidTip: '请选择故障等级',
        faultDescValidTip: '请输入故障描述',
        repairUserValidTip: '请选择维修人',
        repairResultValidTip: '请选择维修结果',
        faultKeyword: '故障名称/故障描述',
        terminalKeyword: '设备名称/设备编号',
        assign: '指派',
        repairTime: '维修时间',
        completeTime: '完结时间'
    },

    doorDisableItem: {
        addTitle: '添加仓门禁用选项',
        editTitle: '修改仓门禁用选项',
        disableReason: '禁用原因',
        disableReasonValidTip: '请输入禁用原因',
        orderNum: '显示顺序',
    },

    faceIdRecharge: {
        purchaseCount: '购买次数',
        unitPrice: '单价',
        totalPrice: '总价',
        auditRecharge: '充值审核'
    },

    partner: {
        addTitle: '添加合作商',
        editTitle: '修改合作商',
        detailTitle: '合作商信息',
        partnerName: '合作商名称',
        contactName: '联系人姓名',
        contactPhone: '联系人手机',
        partnerNameValidTip: '请输入合作商名称',
        keyword: '合作商名称/联系人',
        maxBatteryUser: '电池用户上限',
        maxVehicleUser: '车辆用户上限',
        batteryUserValidTip: '请输入电池用户上限',
        vehicleUserValidTip: '请输入车辆用户上限',
        refundAmountTip: '余额不足',
        balance: '充值账户余额',
        balanceTransaction: '交易记录',
        yeepayTotalBalance: '易宝账户余额',
        yeepaySettleAccount: '易宝待结算账户'
    },

    appConfig: {
        addTitle: '添加APP配置',
        editTitle: '修改APP配置',
        detailTitle: 'APP配置信息',
        payConfig: '支付配置',
        globalConfig: '全局配置',
        appName: '小程序名称',
        appId: 'App ID',
        appSecret: 'App Secret',
        mchId: '商户ID',
        apiKey: 'API密钥',
        payVersion: '支付版本',
        pubKeyModel: '公钥模式',
        appCertP12: 'API证书(apiclient_cert.p12)',
        appCertPem: '公钥文件(apiclient_cert.pem)',
        appKeyPem: '私钥文件(apiclient_key.pem)',
        pubKeyPem: '公钥文件(pub_key.pem)',
        serialNo: '证书序列号',
        certValidDate: '证书有效期',
        aesKey: 'AES密钥',
        appPrivateKey: '应用私钥',
        alipayPublicKey: '支付宝公钥',
        appNameValidTip: '请输入小程序名称',
        appIdValidTip: '请输入App ID',
        appSecretValidTip: '请输入App Secret',
        mchIdValidTip: '请输入商户ID',
        apiKeyValidTip: '请输入API密钥',
        payVersionValidTip: '请输入支付版本',
        appCertP12ValidTip: '请上传API证书',
        appCertPemValidTip: '请上传证书文件',
        appKeyPemValidTip: '请上传私钥文件',
        pubKeyPemValidTip: '请上传公钥文件',
        wxPay: '微信支付',
        aliPay: '支付宝',
        yeepayWx: '易宝微信',
        yeepayAli: '易宝支付宝',
        enableYeepay: '开启易宝支付',
        enableWxPay: '开启微信支付',
        enableAliPay: '开启支付宝',
        yeepayParentMerchant: '易宝上级商编',
        yeepayMerchantNum: '易宝商户编号',
        yeepayParentMerchantValidTip: '请输入易宝上级商编',
        yeepayMerchantValidTip: '请输入易宝商户编号',
        contactService: '客服电话',
        addProtocol: '新增协议',
        userProtocol: '用户协议',
        privacyProtocol: '隐私协议',
        protocolName: '协议名称',
        addContact: '新增客服',
        contactName: '客服名称',
        phoneNo: '电话',
        aboutUs: '关于我们',
        subscribeMsg: '消息订阅',
        wxTemplateConfig: '微信消息模板配置',
        wxLowPowerTemplate: '低电量消息',
        wxChargeCompleteTemplate: '充电完成消息',
        wxBatteryAdventTemplate: '换电套餐即将到期消息',
        wxBatteryExpireTemplate: '换电套餐逾期消息',
        wxVehicleAdventTemplate: '租车套餐即将到期消息',
        wxVehicleExpireTemplate: '租车套餐逾期消息',
        functionDiy: '功能定制',
        vehicleRental: '租车模块',
        batteryCharge: '充电模块',
        inviteFriends: '邀请好友',
        batteryStorage: '电池寄存',
        offlineBatteryExchange: '线下换电',
        forceBatteryExchange: '强制换电',
        enableMultiBatteryCapacity: '换电按容量区分',
        setmealDisplaySkipAuth: '套餐展示跳过认证',
        bindingSite: '注册绑定站点',
        overdueStrategy: '逾期策略',
        enableOverdueStrategy: '开启逾期策略',
        closeBatteryDischargeInterval: '定时关闭电池放电开关',
        batteryFreeOverdue: '电池免费逾期时长',
        vehicleFreeOverdue: '车辆免费逾期时长',
        faceRecognition: '人脸认证',
        accountRecharge: '账户充值',
        authenticationStrategy: '认证策略',
        purchaseFaceCount: '购买认证次数',
        purchaseCountValidTip: '请输入购买次数',
        totalPrice: '总价',
        minSocUsable: '剩余可换次数',
        displayCabinets: '展示电柜',
        auditUser: '审核用户认证',
        uploadSelfie: '上传自拍照',
        enableBleSwap: '蓝牙换电',
        enableExceptionSwap: '卡仓取电',
        faceConfig: '认证配置',
        txSecretId: 'SecretId',
        txSecretKey: 'SecretKey',
        txMerchantId: '商户ID',
        txCaPrivateKey: 'CA证书私钥',
        isOwnAccount: '是否自有账号',
        esign: '电子签',
        esignStrategy: '签署策略',
        esignConfig: '签署配置',
        esignAppId: '签署应用ID',
        esignAppName: '签署应用名称',
        esignSecret: '签署应用secret',
        esignOpenId: '签署企业ID',
        esignOpenName: '签署企业名称',
        brandName: '品牌名称',
        carTypeSign: '租车签署',
        batteryTypeSign: '换电签署',
        vehicleIntegration: '车辆API集成',
        tbitConfig: '泰比特API配置',
        tokenValidTip: '请输入Token',
        gps51Config: '安诺盛API配置',
        gps11Config: '位置科技API配置',
        username: '用户名',
        password: '密码',
        usernameValidTip: '请输入用户名',
        passwordValidTip: '请输入密码',
        userPoints: '用户积分',
        userPointsConfig: '用户积分配置',
        userPointsStrategy: '用户积分策略',
        enableUserPoints: '开启用户积分',
        redeemPointsType: '积分兑换类型',
        addGrade: '新增积分等级',
        editGrade: '修改积分等级',
        pointsGradeName: '等级名称',
        needPoints: '需要积分',
        addPointsGrade: '新增积分等级',
        pointsGradeNameValidTip: '请输入等级名称',
        pointsOutweighValidTip: '请输入需要积分',
        rentDiscount: '租金优惠',
        depositDiscount: '押金优惠',
        increaseLifespan: '增加有效期',
        gradeDiscount: '积分等级优惠',
        addDiscount: '新增等级优惠',
        editDiscount: '修改等级优惠',
        userGradeDiscountValidTip: '请选择用户等级',
        rentDiscountValidTip: '请输入租金优惠',
        depositDiscountValidTip: '请输入押金优惠',
        increaseLifespanValidTip: '请输入增加有效期',
        pointType: '优惠业务类型',
        rentDiscountTables: '请勿设置过高优惠,过高不会生效',
        displayVoltages: '展示电压类型',
        enableUserBindWeChat: '用户绑定微信号',
        enableUserBindAlipay: '用户绑定支付宝',
        enableScanJudgePosition: '扫码判断位置',
        enableCouponProduct: '券包模块',
        wxFeeRate: '微信费率',
        aliFeeRate: '支付宝费率',
        yeepayFeeRate: '易宝费率',
        merchantType: '商户类型',
        merchantSelectTip: '请选择商户类型',
        yeepayMerchantTypeValidTip: '请选择商户类型',
        voltageAlias: '电压展示别名',
        storageDays: '寄存天数',
    },

    mpConfig: {
        mpId: 'AppId',
        mpName: '公众号名称',
        mpSecret: 'AppSecret',
        mpToken: 'Token',
        mpAesKey: 'AESKey',
        templatePush: '模板推送配置',
        pushState: '模板推送',
        terminalAlarmPush: '设备异常推送',
        terminalAlarmTemplateId: '设备异常模板ID',
        alarmTemplateIdTip: '设备异常模板ID不能为空',
        terminalAlarmTypes: '异常推送类型',
        terminalOfflinePush: '设备离线推送',
        terminalOfflineTemplateId: '设备离线模板ID',
        offlineTemplateIdTip: '设备离线模板ID不能为空',
        terminalOfflineTypes: '离线推送类型',
        updateAccessToken: '更新AccessToken',
        updateAccessTokenTip: '确定更新AccessToken吗？'
    },

    mpUser: {
        keyword: '公众号/用户姓名/手机',
        binding: '绑定状态',
        userType: '用户类型',
        globalConfig: '全局配置',
        subscribe: '是否关注',
        subscribeTime: '关注时间',
        mpName: '公众号',
        mpId: '公众号ID',
        openId: '用户OpenId',
        userName: '用户名称',
        phoneNum: '手机号码',
        subscribeScene: '关注场景类型',
        terminalAlarmPush: '设备异常通知',
        terminalOfflinePush: '设备离线通知',
    },

    platformConfig: {
        platformInfo: '平台信息',
        mapInfo: '地图参数',
        ocr: 'OCR识别',
        sms: '短信',
        orgApp: '小程序',
        companyStartYear: '企业成立年份',
        webDomain: '网站域名',
        copyright: '版权信息',
        recordNum: '备案号信息',
        recordWebsiteLink: '备案网站链接',
        browserTitle: '浏览器标题',
        initLat: '地图中心默认纬度',
        initLng: '地图中心默认经度',
        initZoomLevel: '地图默认缩放比例',
        baiduKey: '百度地图key',
        amapKey: '高德地图key',
        aliyun: '阿里云OCR',
        tencentCloudFace: '腾讯云人脸核身',
        faceRecognitionPrice: '单次认证价格',
        faceRecognitionMinPurchase: '单次起购次数',
        accessKeyId: 'AccessKey ID',
        accessKeySecret: 'AccessKey Secret',
        dcloud: 'DCloud',
        unicloudSmsUrl: '云函数URL',
        smsExpireTime: '过期时间',
        smsLen: '验证码长度',
        wxAppConfig: '管理端微信小程序配置',
        cabinetConfig: '电柜配置',
        cabinetBleAuthKey: '蓝牙换电鉴权密钥',
        cabinetBleDataKey: '蓝牙换电数据密钥',
        esignConfig: '电子签',
        mpConfig: '公众号',
        pushState: '模板推送',
        terminalAlarmPush: '设备异常推送',
        terminalAlarmTypes: '异常推送类型',
        terminalAlarmShieldOrgIds: '屏蔽异常推送',
        terminalOfflinePush: '设备离线推送',
        terminalOfflineTypes: '离线推送类型',
        terminalOfflineShieldOrgIds: '屏蔽离线推送',
        organization: "组织范围",
        yeepayConfig: '易宝支付',
        yeepayAppKey: '应用标识AppKey',
        yeepayPrivateKey: 'RSA2048私钥'
    },

    opsCentral: {
        serverMonitor: '服务器监控',
        dataClear: '数据清理',
        dataCache: '数据缓存',
        serviceCtrl: '服务控制'
    },

    announcement: {
        addTitle: '添加公告',
        editTitle: '修改公告',
        detailTitle: '公告信息',
        announcementStatus: '公告状态',
        title: '标题',
        content: '内容',
        expireTime: '过期时间',
        titleValidTip: '请输入标题',
        contentValidTip: '请输入内容',
        expireTimeValidTip: '请选择过期时间',
        publish: '发布',
        offline: '下线',
        publishConfirm: '确认发布吗?',
        offlineConfirm: '确认下线吗?',
        displayScope: '展示范围'
    },

    subscribeMsg: {
        appType: 'APP类型',
        msgType: '消息类型',
        pushMsgTime: '推送消息时间',
        pushStartTime: '推送开始时间',
        pushEndTime: '推送结束时间'
    },

    pushMsg: {
        pushStatus: '推送状态',
        sendParams: '推送内容'
    },

    blacklist: {
        addTitle: '添加黑名单',
        editTitle: '修改黑名单',
        detailTitle: '黑名单信息',
        phoneNum: '手机号码',
        userName: '姓名',
        keyword: '手机/姓名/身份证'
    },

    freezeRecord: {
        freezeStatus: '冻结状态',
        freezeType: '冻结方式',
        thawTime: '解冻时间',
        freezeDuration: '冻结时长'
    },

    overdueRecord: {
        overdueDuration: '逾期时长',
        freeOverdue: '免费逾期',
        finalOverdueDuration: '最终逾期时长'
    },

    faceDetectionRecord: {
        detectionResult: '验证结果',
        detectionReason: '验证失败原因',
        detectionScore: '验证得分',
        platformReason: '平台判定原因',
        detectionCnt: '本轮收费次数',
        detectionDetail: '检测明细',
        detectionTime: '验证时间'
    },

    joinPartnerRecord: {
        partnerName: '合作商名称',
        auditJoinPartner: '审核入组申请',
        groupStatus: '入组状态',
        leaveTime: '退组时间'
    },

    vehicleRentalRecord: {
        rentalRecord: '租车记录',
        rentOperatorName: '租车操作人',
        returnOperatorName: '还车操作人',
        returnTime: '还车时间',
        serviceType: '业务类型',
        rentFiles: '租车照片',
        returnFiles: '还车照片',
        uploadFiles: '上传照片'
    },

    revenueSharingConfig: {
        addTitle: '添加分账配置',
        editTitle: '修改分账配置',
        detailTitle: '分账配置详情',
        sharingEntity: '分账主体',
        entityName: '主体名称',
        entityPhone: '主体手机',
        wxOpenId: '微信openId',
        aliOpenId: '支付宝openId',
        wxMchId: '微信商户号',
        wxMchName: '微信商户全称',
        aliMchId: '支付宝商户ID',
        rentalUserValidTip: '请选择分账用户',
        partnerValidTip: '请选择合作商',
        paymentMethodSelectTip: '请选择支付方式',
        accountMethod: '分账方式',
        byRatio: '按比例',
        fixedAmount: '固定金额',
        accountRatio: '分账比例',
        accountAmount: '分账金额',
        firstPurchaseStrategy: '首购分账策略',
        renewalStrategy: '续费分账策略',
        repeatStrategy: '复购分账策略',
        balancePay: '余额支付',
        feeRateTip: '扣除支付手续费后'
    },

    profitSharingRecord: {
        orderAmount: '订单金额',
        sharingStatus: '分账状态',
        failReason: '分账失败原因',
        sharingOrderNo: '分账订单号',
        orderKeyword: '订单号/分账订单号',
        freezeStatus: '资金解冻状态'
    },

    allocation: {
        allocationOrder: '调拨单号',
        allocationCnt: '调拨数量',
        allocationStatus: '调拨状态',
        transferOrgName: '调出方',
        receiveOrgName: '调入方',
        transferSiteName: '调出站点',
        receiveSiteName: '调入站点',
        receive: '调入接收',
        editTitle: '修改调拨单',
        detailTitle: '调拨单详情',
        addTitle: '新增调拨单',
        terminalNames: '调拨终端',
        receiveUserName: '接收人',
        receiveTime: '接收时间',
    },

    templateBinding: {
        esignConfigId: '所属应用',
        keyword: '业务名称/模板名称',
        projectId: '应用企业标识',
        empowerOrgName: '授权方',
        bindingType: '套餐类型',
        businessTypeName: '签署业务',
        catalogName: '文件夹',
        acceptOrgName: '获取方',
        signTemplateId: '模板标识',
        signTemplateName: '模板名称',
        signingType: '签署类型',
        businessState: '自动签署',
        businessId: '免验证签场景码',
        sealId: '印章ID',
    },
    systemNotice: {
        keyword: '公告标题',
        title: '公告标题',
        publishStatus: '发布公告',
        enableState: '公告通知',
    },
    systemNoticeLog: {
        keyword: '标题/名称/电话号',
    },
    overlayPackage: {
        editOverlayPackage: '修改叠加包',
        addOverlayPackage: '新增叠加包',
        packageName: '叠加包名称',
        setmealStatus: '叠加包状态',
        price: '价格',
        times: '次数',
        orderNum: '排序',
        rentalSetmeals: '绑定租赁套餐',
        overlayPackageListed: '叠加包上架',
        overlayPackageRemoval: '叠加包下架',
        rentalSetmealsTips: '默认全部次数和租电套餐可用',
    },
    yeepayRegister: {
        editTitle: '修改易宝入网',
        addTitle: '新增易宝入网',
        detailTitle: '易宝入网详情',
        applicationStatus: '申请状态',
        registerUser: '入网用户',
        bankName: '开户银行',
        bankAccount: '银行账号',
        address: '地址',
        bankValidTip: '请输入开户银行',
        bankAccountValidTip: '请输入银行账号',
        rentalUserValidTip: '请选择入网用户',
        addressValidTip: '请输入地址',
        queryApplicationStatus: '查询申请状态',
        failReason: '失败原因',
        userKeyword: '手机/姓名/身份证',
        totalBalance: '账户总余额',
        divideAccount: '待分账账户',
        settleAccount: '待结算账户',
        updateAccountTime: '更新账户时间',
        merchantNum: '商户编号',
        district: '所属区县',
        districtValidTip: '请选择所属区县',
    },
    couponProduct: {
        editTitle: '修改券包',
        addTitle: '新增券包',
        detailTitle: '券包详情',
        keyword: '券包名称',
        isQuota: '是否限购数量',
        isBuy: '是否可购买',
        setmealStatus: '货架状态',
        productName: '券包名称',
        productType: '券包类型',
        productDesc: '券包描述',
        orderNum: '排序值',
        quotaQuantity: '限购数量',
        purchasePrice: '购买价格',
        packageItems: '商品项信息',
        siteNamesJoin: '适用站点范围',
        productPrice: '券包价格',
        bindingCoupon: '包含卡券',
        isQuotaPermissionTips: '默认不限单用户购买次',
        quotaQuantityPermissionTips: '限单用户购买次，不限手动派发量',
        orderNumPermissionTips: '值大前排',
        quantity: '数量',
        purchasePricePermissionTips: '券包购买价格',
        orderRecord: '订单记录',
        operationLog: '操作日志',
        setmealRemoval: '下架券包',
        setmealListed: '上架券包',
        couponName: '卡券名称',
        goodsType: '卡券类型',
        distributeCoupon: '派发券包',
        bindingCouponPermissionTips: '仅选购买类型卡券，数量即派发数',
        productTypePermissionTips: '决定站点类型范围，以及生成订单所属站点（租电站点或租车站点）',
        sitePermissionTips: '默认适用指定类型所有站点',
        productNamePermissionTips: '仅用于后台管理，不对用户显示',
        productTypeError: '未知券包类型',
    },
    couponProductOrder: {
        fundFlowRecord: '资金流水记录',
        distributeRecord: '派发记录',
        selectCouponProductTip: '选择券包',
        orderNoNotExist: '订单号不存在',
    },
}
export default lang
